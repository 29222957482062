import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './tier-list.scss';
import { Accordion, Button, Card } from 'react-bootstrap';
import {
  faAnglesDown,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faStar,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import {
  faFaceSadTear,
  faStar as faStarEmpty
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IHSRCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import Switch from 'react-switch';
import { CustomPopover } from '../../modules/cs/common/components/custom-popover';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

interface IHSRCharacterNodes {
  nodes: IHSRCharacter[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [eidolonSpecial, setEidolonSpecial] = useState(true);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_lightning.png"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_wind.png"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Quantum',
            tooltip: 'Quantum',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_quantum.png"
                width={24}
                alt="Quantum"
              />
            )
          },
          {
            value: 'Imaginary',
            tooltip: 'Imaginary',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_imaginary.png"
                width={24}
                alt="Imaginary"
              />
            )
          }
        ]
      },
      {
        key: 'path',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Abundance',
            tooltip: 'Abundance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_abundance.png"
                width={24}
                alt="Abundance"
              />
            )
          },
          {
            value: 'Destruction',
            tooltip: 'Destruction',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_destruction.png"
                width={24}
                alt="Destruction"
              />
            )
          },
          {
            value: 'Erudition',
            tooltip: 'Erudition',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_erudition.png"
                width={24}
                alt="Erudition"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Hunt',
            tooltip: 'Hunt',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_hunt.png"
                width={24}
                alt="Hunt"
              />
            )
          },
          {
            value: 'Nihility',
            tooltip: 'Nihility',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_nihility.png"
                width={24}
                alt="Nihility"
              />
            )
          },
          {
            value: 'Preservation',
            tooltip: 'Preservation',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_preservation.png"
                width={24}
                alt="Preservation"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'moc' && eidolonSpecial === false) {
      setSortOption('moc_special');
    }

    if (sortOption === 'moc_special' && eidolonSpecial === true) {
      setSortOption('moc');
    }

    if (sortOption === 'pure' && eidolonSpecial === false) {
      setSortOption('pure_special');
    }

    if (sortOption === 'pure_special' && eidolonSpecial === true) {
      setSortOption('pure');
    }

    if (sortOption === 'apo' && eidolonSpecial === false) {
      setSortOption('apo_special');
    }

    if (sortOption === 'apo_special' && eidolonSpecial === true) {
      setSortOption('apo');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.path && activeFilters.path !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.path.toLowerCase() === activeFilters.path.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, eidolonSpecial]);

  const sortingOptions = [
    { value: 'moc', label: 'Memory of Chaos' },
    { value: 'pure', label: 'Pure Fiction' },
    { value: 'apo', label: 'Apocalyptic Shadow' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Tier list (October 2024)</h1>
          <h2>
            The most popular tier list for Honkai: Star Rail that rates all
            available characters by their performance in Memory of Chaos, Pure
            Fiction and Apocalyptic Shadow.
          </h2>
          <p>
            Last updated: <strong>05/10/2024</strong>
          </p>
        </div>
      </div>
      {/* <div className="fuse-ad-placeholder" data-fuse="22844297004"></div> */}
      <div className="afk-ad-banner">
        <div className="cta">
          <h4>New Season "Waves of Intrigue" is now live in AFK Journey</h4>
          <p>Unveil the mysteries of Rustport and uncover new adventures!</p>
          <OutboundLink href="https://bit.ly/PrydwenHSR" target="_blank">
            <Button variant="primary">Download Now</Button>
          </OutboundLink>
        </div>
      </div>
      <div className="tier-list-page">
        <SectionHeader title="Honkai: Star Rail Tier list" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <p>
                Please keep in mind that Honkai: Star Rail{' '}
                <strong>is a game where team building matters most</strong> and
                while our tier list takes the optimal setup into account, a lot
                of characters can work and do well - even those ranked lower -
                when you invest into them. Also for story or lower difficulties
                of Simulated Universe, you don't need to worry about ratings and
                tiers. You can safely clear that content even with F2P
                characters as long as you build your team correctly.
              </p>
              <p>
                These tier lists rate characters based on their average
                performance in{' '}
                <strong>
                  Memory of Chaos, Pure Fiction and Apocalyptic Shadow
                  regardless of turbulence, whimsicality and cacophony
                </strong>
                . Characters rated higher will perform well without the need to
                rely on these and will only benefit more from receiving them. As
                new mechanics, characters and challenges are introduced each
                tier list will be updated.
              </p>
              <p>
                <strong>
                  Important! Characters are ordered alphabetically within a tier
                </strong>
                .
              </p>
              <p>Available tier lists:</p>
              <ul>
                <li>
                  <strong>Memory of Chaos (MoC)</strong> - how the character
                  performs in the Memory of Chaos. Blast and single target
                  damage are important here while AoE has niche uses against
                  some bosses.
                </li>
                <li>
                  <strong>Pure Fiction (PF)</strong> - how the character
                  performs in the Pure Fiction. AoE is king here, followed by
                  Blast while single target damage is mostly useless.
                </li>
                <li>
                  <strong>Apocalyptic Shadow (AS)</strong> - how the character
                  performs in the Apocalyptic Shadow. Single target and Break
                  potential are heavily favored here.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Categories & Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Categories</h6>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">Damage Dealer</strong> - Characters
                  that focus on dealing direct damage and can perform their role
                  unhindered by enemy type, enemy element or team composition,
                </li>
                <li>
                  <strong className="specialist">Specialist</strong> -
                  Characters that excel under specific circumstances carrying a
                  unique role that often requires a different and divergent
                  playstyle,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Characters
                  that do not focus on dealing damage but instead amplify the
                  damage other teammates deal by granting allies buffs or
                  debuffing enemies,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Characters who
                  are capable of keeping the team alive with their abilities.
                </li>
              </ul>
              <h6>Special tags</h6>
              <p>
                Tags are split into 3 categories:{' '}
                <strong className="positive">Pros</strong>,{' '}
                <strong className="negative">Cons</strong> and{' '}
                <strong className="archetype">Archetype</strong> which represent
                the most defining features of a character's kit and the current
                meta archetypes they fit into. Archetype tags indicate a
                connection between characters with that tag e.g. Acheron
                requires debuffs to work and Silver Wolf applies them. Here is a
                full list of all current tags and what they mean:
              </p>
              <ul>
                <li>
                  <strong className="archetype">Break</strong> - the character
                  belongs to the Break archetype focusing on Break Effect and
                  Break Efficiency,
                </li>
                <li>
                  <strong className="archetype">Debuff</strong> - the character
                  belongs to the Debuff archetype applying one or many debuffs
                  or has a kit that directly relies on them to function,
                </li>
                <li>
                  <strong className="archetype">DoT</strong> - the character
                  belongs to the Damage-over-Time archetype specializing in
                  applying and/or amplifying DoT effects,
                </li>
                <li>
                  <strong className="archetype">FuA</strong> - the character
                  belongs to the Follow-up Attack archetype benefitting from,
                  buffing or delivering powerful Follow-Up Attacks.,
                </li>
                <li>
                  <strong className="positive">Advance</strong> - the character
                  can manipulate the turn order by either action advancing their
                  allies or themselves,
                </li>
                <li>
                  <strong className="positive">Buff</strong> - the character can
                  apply various Buffs to help their teammates,
                </li>
                <li>
                  <strong className="positive">Delay</strong> - the character
                  can delay enemies and hinder their actions in battle,
                </li>
                <li>
                  <strong className="positive">Energy</strong> - the character
                  can recover the teammates energy,
                </li>
                <li>
                  <strong className="positive">SP-Friendly</strong> - the
                  character is substantially more skill point friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">SP-Unfriendly</strong> - the
                  character is substantially more skill point un-friendly than
                  other characters in their role.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings & Meta Lines</Accordion.Header>
            <Accordion.Body>
              <h5>Meta Lines</h5>
              <p>
                Meta Lines categorize each section of the tier list into a power
                bracket. Here are the four brackets:
              </p>
              <ul>
                <li>
                  <strong className="tier-05">
                    Apex Characters (T0 & T0.5)
                  </strong>{' '}
                  - characters in this bracket are the cream of the crop. They
                  can do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear the content,
                </li>
                <li>
                  <strong className="tier-2">
                    Meta Characters (T1, T1.5, T2)
                  </strong>{' '}
                  - characters in this bracket make clearing the content a
                  breeze, but compared to the Apex characters they require more
                  work from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine,
                </li>
                <li>
                  <strong className="tier-4">Niche Characters (T3 & T4)</strong>{' '}
                  - characters in this bracket are lacking in one or many fields
                  of their role. These characters still possess the ability to
                  clear all content with the right build, endgame blessing, team
                  or enemy line-up but have much lower play rates and success
                  rates than Meta and Apex characters,
                </li>
                <li>
                  <strong className="tier-5">The Forgotten Ones (T5)</strong> -
                  characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </li>
              </ul>
              <h5>Half tiers</h5>
              <p>
                To further define the tier list and correctly represent the
                power level of each character in relation to each other we have
                added half tiers to the higher tier brackets. Under our previous
                system a tier rating jump of 1 tier rating made it difficult to
                position characters accurately, often leading to multiple
                characters ending up in the same tier despite a clear power and
                utility difference.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Criteria</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>Manual play,</li>
                <li>Using optimal team composition/support characters,</li>
                <li>Character level 80,</li>
                <li>
                  All Major Traces unlocked and skills upgraded to level 8,
                </li>
                <li>
                  Character equipped with level 80{' '}
                  <strong>non-limited 4★ or 5★ Light Cone</strong> (including
                  the 'standard' Light Cones),
                </li>
                <ul>
                  <li>
                    Battle Pass Light Cones aren't taken into account because
                    you can't obtain them without spending,
                  </li>
                </ul>
                <li>
                  Character equipped with 5★ upgraded gear with optimal Main
                  Stats and Sub Stats (with 24 desired sub stats out of a
                  possible 54),
                </li>
                <li>Eidolon level 0 for 5★ characters,</li>
                <li>Eidolon level 0/6 for 4★ characters.</li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so damage dealers are
                compared to other damage dealers and the ratings are adjusted
                based on their performance within the role. Don't try to compare
                characters between roles (for example an amplifier with a damage
                dealer), because their ratings mean different things.
              </p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">Damage Dealer</strong> - Rating is
                  heavily influenced based on the characters ability to deal
                  enough damage to achieve the 3-star objective, the easier and
                  more quickly achieved the higher the rating,
                </li>
                <li>
                  <strong className="specialist">Specialist</strong> - Rating is
                  heavily influenced based on the damage they provide, defense
                  or efficiency boost created by the unique teams these
                  characters create or the utility they provide against certain
                  encounters,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Rating is
                  influenced based on the damage amplification abilities of the
                  character and the utility they provide to the team,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Rating is
                  influenced based on the characters ability to keep the team
                  alive and the utility they provide. The more utility and
                  higher the safety, the higher the rating.
                </li>
              </ul>
              <h6>Other criteria affecting the ratings</h6>
              <p>
                Each tier list rates characters based on their average value in
                the specific game mode it represents.{' '}
                <strong>
                  The higher a character is rated on the tier list the greater
                  their power and more widely they can be used effectively in
                  the mode
                </strong>
                . We consider:
              </p>
              <ul>
                <li>
                  <strong>Flexibility</strong> - how the characters kit
                  functions against various enemy types, amounts of enemies and
                  their ability to perform when not fighting against enemies
                  vulnerable to their element,
                </li>
                <li>
                  <strong>Investment</strong> - some characters require specific
                  limited 5★ units, heavy investment in certain relic sets or an
                  abundance of sub-stats in order to function at the highest
                  level. Each of these points impacts the rating in a negative
                  way. - the more the character requires to perform well, the
                  bigger the impact,
                </li>
                <li>
                  <strong>Utility</strong> - recently released characters
                  possess abilities that lie beyond their defined role, for
                  example Amplifiers/Sustains that can deal decent damage. The
                  more a character helps the team outside of their role, the
                  bigger positive impact on their rating it has.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>
                <strong>05/10/2024</strong>
              </h6>
              <p>
                We have updated the Pure Fiction tier list based on the latest
                data (check{' '}
                <Link to="/star-rail/pure-fiction">Pure Fiction Analytics</Link>
                ), our own testing and community feedback. Here are the changes:
              </p>
              <ul>
                <li>
                  <strong>[Argenti]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Despite a bit underwhelming performance in the most recent
                  Pure Fiction phase, Argenti still is one of the best
                  characters you can bring into this mode - this phase was as
                  bad as it could be for him and he still reached nearly 30k
                  average score. After looking at his average performance in the
                  last 3 phases, so what's being taken into account for the
                  purpose of the tier list, and after testing him in various
                  teams, we think he deserves to sit in T0 alongside Herta and
                  Himeko.
                </li>
                <li>
                  <strong>[Blade]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Blade's average performance improved in the last few
                  phases as he found a new home alongside Jade (though he's not
                  played exclusively with her) - so we have decided to raise him
                  alongside Clara to the T1 tier.
                </li>
                <li>
                  <strong>[Firefly]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Lingsha has brought a second life to Firefly and enabled
                  her to shine in Pure Fiction - though Firefly is playing a
                  more 'supportive' role in that team, thanks to her insane
                  Weakness Implant ability via her Technique and Skill, allowing
                  Himeko and Lingsha to decimate the waves of enemies.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. The watchlist strikes again. After gathering more data
                  and doing more testing, we are pretty sure that Ruan Mei has
                  lost the battle of supremacy in Pure Fiction. Even if this
                  phase wasn't kind to the singing amplifier, she still sits
                  just behind Ruan Mei in average score, but when you look at
                  the performance across last few phases, Robin performs better
                  and is often the first choice in most archetypes - outside of
                  the Break one obviously. Ruan Mei is still an Apex tier
                  character due to her flexibility and ease of use, but Robin is
                  just something else in this mode, so she will remain the sole
                  T0 character (for now).
                </li>
                <li>
                  <strong>[Jing Yuan]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Time wasn't kind to our general. His performance continues
                  to drop in Pure Fiction as he fails to even reach the 30k
                  points threshold yet again (both in most recent phase and
                  across last 3 phases). Even if you do everything right when
                  playing him, he is outdamaged by newer and shinier damage
                  dealers, who also don't need to worry about LL using his
                  attack either too soon or too late.
                </li>
                <li>
                  <strong>[Moze]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Moze just can't keep up with Pure Fiction's pace and while
                  we initially thought he will have some niche uses, we were
                  mistaken. So he's being dropped to the Forgotten Tier where he
                  belongs.
                </li>
                <li>
                  <strong>[Serval]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. On paper, Serval looks like she has everything you want in
                  Pure Fiction from a DPS, but sadly the reality is cold and
                  brutal. While in the past she actually performed decently
                  well, being a 4-star release character took it's toll on her
                  and her performance continues to decrease, so we're dropping
                  her down a tier.
                </li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations — we place them on our internal
                'Watch list'. This helps us avoid hasty tier adjustments and
                ensures that any changes we make are well-considered and
                accurate. We welcome your feedback! This are the characters
                being added to the watch list in PF mode:
              </p>
              <ul>
                <li>
                  <strong>[Jiaoqiu]</strong> - saying that the Foxian exceeded
                  our expectations is a understatement of the year. Not only he
                  jumped to the Apex tier post his release, but now he's
                  threatening Robin - his average scores were the highest in
                  this phase and he's nearly on par with Robin when you look at
                  the average data in last two phases. Still, we want to gather
                  one more dataset before deciding whether he should join Robin
                  in T0 (as he only was tested in last two phases).
                </li>
                <li>
                  <strong>[Feixiao]</strong> - Feixiao had a decent debut in
                  Pure Fiction despite being a Hunt character and we want to
                  observe her more before deciding whether to move her up or
                  keep her in T1.5.
                </li>
                <li>
                  <strong>[Seele]</strong> - Seele, outside of Shatter phases,
                  continues to struggle, but we know that in the right hands,
                  she has no issues reaching great scores even in 'neutral'
                  phases. We want to take a closer look on her the next phase,
                  before deciding what to do with her.
                </li>
                <li>
                  <strong>[Jingliu]</strong> - Jingliu is on the opposite side
                  of the spectrum compared to Seele and Feixiao. Her performance
                  continues to drop and she even failed to get on the
                  'scoreboard' in the most recent phase - her usage rate is
                  abysmal and so is her performance. Most likely in the next
                  phase she will drop to the Niche tier.
                </li>
              </ul>
              <h6>
                <strong>02/10/2024</strong>
              </h6>
              <p>
                We have added <strong>[Lingsha]</strong> to the tier list. No
                other changes have been made, but the Pure Fiction tier list
                will be updated in a few days - we're still gathering data for
                the current phase.
              </p>
              <h6>
                <strong>22/09/2024</strong>
              </h6>
              <p>
                We have updated the AS tier list based on the latest data (check{' '}
                <Link to="/star-rail/apocalyptic-shadow">
                  Apocalyptic Shadow Analytics
                </Link>
                ), our own testing and community feedback. Here are the changes:
              </p>
              <ul>
                <li>
                  <strong>[Yunli]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Well, I guess some of you have a deja vu now, because we
                  already made similar change in MoC and PF. Yes, we
                  underestimated Yunli on her release and she's truly Apex level
                  in every mode - even without her Signature Cone. In
                  Apocalyptic Shadow, she performs really well, doing tons of
                  damage and break with her often counters, making her one of
                  the strongest DPS you can bring in this mode (or any other).
                </li>
                <li>
                  <strong>[Argenti]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Argenti shines in the Aventurine mini game, but he
                  performed really well even before that - his average scores
                  across the last phases are above average (but his usage is
                  really low). And our own testing matches the data, so we have
                  decided to raise Argenti a tier. And guess I can spoil it -
                  he's being raised next week in PF too, so it's a good time for
                  Argenti mains!
                </li>
                <li>
                  <strong>[Jade]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Jade continues to surprise us with her performance in
                  non-Pure Fiction modes. While she's nowhere near the Apex
                  tier, players manage to find ways to use her strong sides,
                  allowing her to reach respectable performance. We're giving
                  her a nod because of that and are raising her to T1.5.
                </li>
                <li>
                  <strong>[Luka]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Luka's punches hurt. A lot. His performance continues to
                  improve with every new phase and it didn't make sense to keep
                  him in T2 tier, especially after the last showing. So he's
                  going up just like Jade did.
                </li>
                <li>
                  <strong>[Silver Wolf]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Well, it was bound to happen one day. Silver Wolf lost her
                  last Apex spot mainly because of underwhelming performance
                  across last AS phases that is caused by her struggling to find
                  a spot in any of the top meta teams. Her Implant Weakness is
                  not as valuable as in the past - people build more damage
                  dealers and aren't forced to rely on her. She's being dropped
                  to T1 for now - potentially she can move even lower in the
                  future.
                </li>
                <li>
                  <strong>[Pela]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Pela still performs really well and is the champion of
                  F2P players, but when you compare her to other characters
                  sitting in T1 - especially Jiaoqiu - she looks out of place.
                  Because of that we're dropping her down half a tier,but will
                  continue to monitor her in case her performance will improve
                  (or some other changes will happen to the T1 tier soon).
                </li>
                <li>
                  <strong>[Xueyi]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Xueyi initially looked like a character perfectly made
                  for this mode, but sadly reality check hit her hard. In the
                  data, she's one of the least popular characters with one of
                  the worst scores and our testing confirms that. She only
                  shines when the enemies match her kit perfectly, but outside
                  of that she's pretty useless. We will continue to monitor her,
                  but most likely she will drop further down next phase - as
                  she's way to reliant on the enemies.
                </li>
                <li>
                  <strong>[Sushang]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. A very similar case to Xeuyi - Sushang had her chance to
                  shine but even if there are Phys-weak enemies she fails to
                  perform well, so we're dropping her down a tier.
                </li>
                <li>
                  <strong>[Lynx]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Lynx drops from the Meta tier to the Niche tier. Compared
                  to Bailu and Gepard, her performance is underwhelming and she
                  has issues keeping the team alive against the hard-hitting
                  bosses you fight in AS.
                </li>
                <li>
                  <strong>[Hook]</strong> & <strong>[Misha]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Low usage rate + terrible performance in both our data and
                  our testing.
                </li>
                <li>
                  <strong>[Dan Heng]</strong> & <strong>[Serval]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Low usage rate + terrible performance in both our data and
                  our testing.
                </li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations — we place them on our internal
                'Watch list'. This helps us avoid hasty tier adjustments and
                ensures that any changes we make are well-considered and
                accurate. We welcome your feedback! This are the characters
                being added to the watch list in AS mode:
              </p>
              <ul>
                <li>
                  <strong>[Robin]</strong> - Feixiao release made FUA even
                  stronger and the characters that are part of the meta FART
                  team simply dominated this phase (but not only them). Still,
                  we want to wait one more phase to see how they will settle
                  into the meta, before considering raising Robin to T0 tier.
                </li>
                <li>
                  <strong>[Sparkle]</strong> - Well... Our resident trickster
                  been having rough last few weeks. She struggles to find a spot
                  for herself in the meta as none of the best teams in AS need
                  her and we're considering dropping her from T1, but we want to
                  give her one more phase before we pull the trigger - maybe she
                  will surprise us.
                </li>
              </ul>
              <p>
                Also, the{' '}
                <Link to="/star-rail/apocalyptic-shadow">
                  Apocalyptic Shadow Analytics
                </Link>{' '}
                now features a new section that shows the data across last 3
                phases of this mode, so what's being taken into account when we
                create the tier list. Here's a sneak peak of how it looks like
                (MoC already has a similar section):
              </p>
              <StaticImage
                src="../../images/starrail/as_promo.webp"
                alt="Tier list"
              />
              <br /> <br />
              <p>
                One more thing for today - since we have updated the{' '}
                <Link to="/star-rail/memory-of-chaos">MoC Analytics</Link> to
                include Feixiao and Moze, we gathered more data on Acheron and
                Jiaoqiu. Acheron will stay in T0 for now and{' '}
                <strong>Jiaoqiu has been raised to T0.5</strong> - he elevates
                Acheron, allowing her to perform at the highest level, but also
                sees usage in other teams (Dr. Ratio, Imbibitor or DoT duo -
                though they perform worse than the Acheron team where he feels
                at home).
              </p>
              <h6>
                <strong>10/09/2024</strong>
              </h6>
              <p>
                <strong>
                  We have made some edits to the changelog to clarify a few
                  things and fix some wording issues.
                </strong>
              </p>
              <p>
                We have updated the MoC tier list based on the latest data
                (check MoC Analytics), our own testing and community feedback.
                Here are the new additions:
              </p>
              <ul>
                <li>
                  Added <strong>[Feixiao]</strong> to the tier list:
                </li>
                <ul>
                  <li>
                    We're placing Feixiao in T0 and keeping Acheron alongside
                    her for the time being.
                  </li>
                  <li>
                    In our pre-release testing Feixiao exceeded all our
                    expectations and completely obliterated the current phase of
                    Memory of Chaos. We are certain she is one of, if not the
                    strongest, CRIT-Based single target damage dealer in the
                    game. She also has incredible team diversity and options at
                    all investment levels from free to play all the way up to
                    high spenders. We know she is deserving of Tier 0 but that
                    poses the question - should Acheron remain alongside her?
                  </li>
                  <li>
                    Acheron has been at the top of our MoC tier list since her
                    release but as of late has begun to show signs of losing her
                    edge. At the same time Follow-Up and Break archetypes has
                    continued to gain improvements via new Relics or characters
                    - this led our team to begin strongly reconsidering her
                    place at the top. This all changed with the release of
                    Jiaoqiu - a character tailor made to synergize with Acheron
                    who has now proven on his debut to greatly increase her
                    performance while also performing very well himself. As a
                    result of this Acheron has seen one of her best MoC showings
                    yet while having an incredibly high play rate.
                  </li>
                  <li>
                    Between Feixiao's explosive performance in testing and
                    Acheron's sudden massive power boost thanks to Jiaoqiu we
                    want to give both team archetypes the time needed to settle
                    into the meta before making the call on Acheron and
                    Jiaoqiu’s final placements. We'll revisit Jiaoqiu and
                    Acheron's placements again sooner than our usual update with
                    our decision.
                  </li>
                </ul>
                <li>
                  Added <strong>[Moze]</strong> to the tier list.
                </li>
              </ul>
              <p>And here's the list of changes we made:</p>
              <ul>
                <li>
                  <strong>[Yunli]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Yunli has more than proven herself as one of the most
                  powerful damage dealers in MoC even without her signature
                  Light Cone - with it she’s even stronger. She thrives in
                  multiple team compositions, both premium and F2P, and is
                  absolutely Apex character material. Because of this we’re very
                  happy to move the greatsword user into Tier 0.5 where she
                  deserves to be.
                </li>
                <li>
                  <strong>[Clara]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Clara has been consistently usable and strong in MoC’s
                  with enemies that either hit quickly or multiple times per
                  action - and over time we’ve seen an increase to both. Like
                  the recently released, and now Apex tier, Yunli her team
                  compositions are also flexible and strong for both spenders
                  and F2P alike, especially as she’s a standard banner
                  character. Clara has her place in the meta and sees consistent
                  use and improvements (Follow-Up Buffs) while most other 1.x
                  characters are experiencing the effects of power creep and a
                  lack of support. In our testing Clara also consistently
                  overperforms compared to characters she shares a tier with.
                  For all these reasons we’re raising clara up from Tier 1.5 to
                  Tier 1.
                </li>
                <li>
                  <strong>[Topaz]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Topaz has seen strong meta representation and relevance
                  thanks to her synergy with Clara, Dr Ratio and now Yunli. But
                  with the release of Feixiao that is only going to surge to new
                  heights. Topaz is one of Feixiao’s best teammates and a part
                  of one of the new strongest teams in the game FART (Feixiao,
                  Aventurine, Robin, Topaz). Based on both her new and existing
                  synergies with some of the best characters in the game we’re
                  raising her into the Apex category of tiers.
                </li>
                <li>
                  <strong>[Robin]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. We had Robin slated for an upgrade to Tier 0 in this
                  Memory of Chaos (MoC) based on her past performance and
                  continued relevance in the meta. However, the release of
                  Feixiao has only solidified this decision. Robin's synergy
                  with Feixiao has made her value undeniable, reinforcing her
                  importance within top-tier team compositions. Without any
                  shadow of a doubt, Robin is now officially moving to Tier 0, a
                  well-deserved placement given her outstanding contributions to
                  the current meta.
                </li>
                <li>
                  <strong>[Huohuo]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Huohuo has been the go to sustain for many team
                  compositions when playing with performance in mind (tuning and
                  manual play) - thanks to her energy regeneration, cleanses and
                  ATK% boost for a while now. Because of this we’ve had her on
                  our radar as a candidate to join Aventurine in T0 for a while
                  now. The release of Yunli tipped the scales as Huohuo gained
                  another top tier character that she wants to be always
                  partnered with and who she elevates to new heights.
                </li>
                <li>
                  <strong>[Black Swan]</strong> & <strong>[Kafka]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. As previously discussed, DoT has not been performing at
                  the apex level for an extended period of time. We’re following
                  through on our decision to lower both Kafka and Black Swan as
                  a result of this and their continued lack of support. We
                  expect these characters are likely to return to form when the
                  developers finally give DoT some attention again.
                </li>
                <li>
                  <strong>[Silver Wolf]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Silver Wolf still retains a solid position in the meta
                  when her services are needed, but we believe she no longer
                  measures up to the current meta relevance and performance of
                  units like Jiaoqiu or Tingyun who remain in T1. As a result,
                  we are adjusting her tier rating downward to more accurately
                  reflect her power level relative to the other units she shares
                  her tier with.
                </li>
                <li>
                  <strong>[Jing Yuan]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Jing Yuan performed below expectations in the last few
                  Memory of Chaos phases - based on our own testing, player
                  gathered data (across last few phases), and community
                  feedback. While in the most recent phase his performance has
                  improved, it's caused by low play rate (below 1%) leading to
                  skewed results at the hand of a few players. In scenarios like
                  this where a character exceeds expectations but has a low play
                  rate we double our efforts in testing them in order to
                  understand whether it warrants inclusion in the current round
                  of evaluations or not - we specifically test the teams that
                  overperformed in said data. Overall, after manually testing
                  him, we found out that Jing Yuan struggles to find a place in
                  the current meta and that's the reason of his drop in
                  popularity and rankings. And he's not alone there - most 1.x
                  limited damage dealers are starting to show their age and most
                  likely others will join him soon.
                </li>
                <li>
                  <strong>[Qingque]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2
                </li>
                <li>
                  <strong>[Bailu]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3
                </li>
                <li>
                  <strong>[Serval]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4
                </li>
                <li>
                  <strong>[Misha]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4
                </li>
                <li>
                  <strong>[Dan Heng]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5
                </li>
                <li>
                  <strong>[Herta]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5
                </li>
                <li>
                  <strong>[Hook]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5
                </li>
              </ul>
              <p>
                Each of the characters without a dedicated reason for the rating
                change has one or multiple of the issues:
              </p>
              <ul>
                <li>Lack of Meta impact suited for their tier.</li>
                <li>Play rate consistently below expectations.</li>
                <li>
                  Underwhelming / inconsistent results across multiple phases of
                  testing.
                </li>
                <li>
                  Not having enough meaningful & dedicated Support to match
                  increasing game difficulty.
                </li>
                <li>
                  Victims of power creep compared character's who share the same
                  Niche but with far superior performance or support.
                </li>
                <li>Suffers at the hands of new enemy mechanics.</li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations — we place them on our internal
                'Watch list'. This helps us avoid hasty tier adjustments and
                ensures that any changes we make are well-considered and
                accurate. We welcome your feedback!
              </p>
              <ul>
                <li>
                  <strong>[Jingliu]</strong> - Jingliu hasn’t been seeing the
                  play rate, performance or meta relevance she previously
                  retained for quite some time now - with this MoC being a
                  particularly underwhelming showing for her. Jingliu hasn’t
                  received any major dedicated support since the time of her
                  release, only seeing minor improvements or variations through
                  Ruan Mei, Sparkle and Robin. None of which are designed for
                  her and are usually much better played in other compositions
                  they synergise better with.
                </li>
                <li>
                  <strong>[Acheron] & [Jiaoqiu]</strong> - As mentioned earlier
                  we’re placing both an “Express” watch list to check how they
                  perform against Feixiao. Acheron can maintain her spot or drop
                  to T0.5 while Jiaoqiu can potentially move to T0.5 depending
                  on how they will perform in our testing and the community's
                  hands in the near future.
                </li>
                <li>
                  <strong>[Jade] & [Argenti]</strong> - Previously, we placed
                  both Jade and Argenti on the watchlist as potential candidates
                  for tier promotion. However, upon further testing and analysis
                  of our collected data, neither has been able to sustain the
                  strong performance initially observed. Despite their early
                  promise, both characters have fallen short in their recent
                  showings. As a result, we will maintain their current tier
                  placements for the time being and remove them from the
                  watchlist.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="5">
            <Accordion.Header>Older changelog</Accordion.Header>
            <Accordion.Body>
              <h6>
                <strong>25/08/2024</strong>
              </h6>
              <p>
                We have updated the Pure Fiction tier list based on the latest
                data (check PF Analytics), our own testing and community
                feedback. List of changes:
              </p>
              <ul>
                <li>
                  <strong>[Jiaoqiu]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Our initial assessment of Jiaoqiu in Pure Fiction was
                  conservative, with the intent to raise him based on community
                  reception, team synergy, and performance with and without his
                  Signature Light Cone. Now, with results in hand, we’re more
                  than satisfied with his initial scores and performance, even
                  without his Signature. While we’re still monitoring his
                  ability to find a consistent place outside of Acheron teams,
                  Jiaoqiu has done enough to earn his Apex status. We’ll be
                  raising him to T0.5 while continuing to keep an eye on his
                  team variety.
                </li>
                <li>
                  <strong>[Yunli]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. After further testing, community feedback, and solid
                  data performance, it is clear that Yunli is one of the
                  strongest DPS in Pure Fiction - with or without her Light
                  Cone. The current Pure Fiction buff is excellent for Yunli,
                  but her strength is evident regardless. She boasts some of the
                  most frequent attacks of any DPS, excellent AoE capabilities,
                  very frequent Ultimates, Argenti-style multi-hit potential,
                  numerous viable teams, and realistic solutions for those who
                  don't have her Signature Light Cone. Based on all of this, we
                  are raising her into the Apex set of tiers.
                </li>
                <li>
                  <strong>[Acheron]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. In our previous tier list iteration, we dropped Acheron
                  from the Apex tier due to her poor and inconsistent
                  performance in the mode. She displayed non-Apex results within
                  both her most common teams, DoT and Double Debuff, neither of
                  which scored high enough to maintain her ranking. However, the
                  release of Jiaoqiu has changed the landscape, allowing Acheron
                  to achieve Apex-worthy scores once more. Jiaoqiu's ability to
                  provide Acheron with far more Ultimates and consistent Damage
                  Amplifications, even as new enemies spawn, has revitalized her
                  performance. Despite this, her average score remains low in
                  our data, largely because players without Jiaoqiu continue to
                  struggle, dragging down her average clears. Given that the
                  tier list is ranked with characters' best teams in mind, we
                  are raising Acheron back to the Apex tier.
                </li>
                <li>
                  <strong>[Seele]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. This phase, Seele has performed exceptionally well,
                  exceeding her previous tier list rating and achieving
                  impressive results in the community, our testing, and our data
                  collection. A major factor contributing to this success is the
                  Pure Fiction "Shatter" buff mechanic, which significantly aids
                  Seele in dealing with large groups of enemies in the mode.
                  While this buff greatly enhances her performance, Seele's
                  ability to achieve higher scores when the buff is active,
                  combined with her solid "standard" performance, warrants a
                  tier upgrade to reflect her moments of dominance.
                </li>
                <li>
                  <strong>[March 7th - The Hunt]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Swordmaster March 7th has had an impressive first
                  showing in Pure Fiction, exceeding expectations when paired
                  with the mode's premier Follow-Up Attackers such as Yunli and
                  Herta. March has achieved higher scores across multiple
                  different team compositions, including various main carries.
                  Given her strong performance, we feel it is appropriate to
                  raise her up a tier from T2 to T1.5.
                </li>
                <li>
                  <strong>[Bronya]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. As Jade has settled into the Pure Fiction meta, Bronya has
                  found a solid place alongside her and Blade in one of the
                  top-performing combinations in the mode. In this phase, Bronya
                  has also seen success with Argenti in multiple team
                  variations, all achieving impressive scores. Due to her
                  ability to secure a place alongside several powerhouse teams
                  in the mode and score well consistently, we’re raising Bronya
                  to T1.
                </li>
                <li>
                  <strong>[Hanya]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Hanya currently has one of the lowest play rates among
                  all Amplifiers, primarily seeing widespread play only
                  alongside Argenti. Despite her low usage, Hanya has
                  consistently achieved solid scores over the past two phases,
                  often outperforming characters ranked higher than her. Her
                  success is largely tied to how well Argenti performs in the
                  current phase, but her synergy with him is strong enough to
                  justify a tier adjustment. We’re raising Hanya to T1.5 for now
                  placing her in same tier as Asta, recognizing her value and
                  effectiveness, but will continue to monitor her performance
                  closely.
                </li>
                <li>
                  <strong>[Lynx]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. Lynx has experienced a notable increase in meta relevance,
                  performance, and play rate following the release of Yunli. Her
                  role in the current meta has become more significant,
                  especially in supporting Yunli players who lack her Signature
                  Light Cone, making it easier for them to withstand hits in the
                  mode. To reflect this, we’re raising Lynx from T3 to T2,
                  recognizing her growing importance and effectiveness within
                  the current meta.
                </li>
                <li>
                  <strong>[Jing Yuan]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Jing Yuan has underperformed in the previous two phases
                  of Pure Fiction - both in our own testing and in the eyes of
                  the community. The data reflects this disappointing trend as
                  well. Consequently, we are moving him down from Tier 1 to Tier
                  1.5. However, we will continue to monitor his performance
                  closely to determine if further adjustments are necessary.
                </li>
                <li>
                  <strong>[Firefly]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Firefly, along with the Break archetype, initially
                  gained popularity in Pure Fiction. However, their performance
                  has been on a downward trend since their debut. We've been
                  closely monitoring Firefly's performance since her release and
                  feel that she no longer meets her previous Tier 1 rating.
                  Given her more recent results, we are adjusting her rating
                  from Tier 1 to Tier 1.5 for now.
                </li>
                <li>
                  <strong>[Imaginary Trailblazer]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Imaginary Trailblazer has experienced a weak performance
                  over the past two phases - showing underwhelming scores in
                  Pure Fiction. The necessity of Breaking enemies before the
                  Super Break can take effect has proven to be a less effective
                  strategy compared to other options in the mode. As a result,
                  we’re lowering Imaginary Trailblazer’s rating to T1.5 for now,
                  with the potential for further adjustments based on future
                  performance.
                </li>
                <li>
                  <strong>[Guinaifen]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Based on our latest data, testing, and community feedback
                  under the new Pure Fiction mode, we’re revising Guinaifen’s
                  Amplifier tier list placement, moving her to T3. With the
                  release of Jiaoqiu and extensive testing and comparison of
                  both characters across various teams in the mode, it’s clear
                  that Guinaifen doesn’t measure up. Currently, she lacks a
                  specialty or a team where she excels, making the Niche tier
                  the most appropriate placement for her at this time.
                </li>
                <li>
                  <strong>[Sampo]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Sampo has seen very limited play and mixed performance
                  results, even during phases of Pure Fiction that focus on DoT
                  mechanics. His lack of consistent success has led us to
                  reassess his standing. As a result, we are moving Sampo out of
                  the Meta tiers and into the Niche tiers, where his
                  representation in the mode is more accurately reflected.
                </li>
                <li>
                  <strong>[Serval]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Serval is no longer seeing consistent play across Pure
                  Fiction phases and has struggled as the mode continues to
                  increase in difficulty. Her low play rate and underwhelming
                  performance indicate that she is no longer a meta pick for
                  this mode. As a result, we are moving her into the niche
                  tiers.
                </li>
                <li>
                  <strong>[Imbibitor Lunae]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Imbibitor Lunae has never been a popular pick for Pure
                  Fiction, nor has he scored particularly well. Recently, his
                  performance has declined even further, accompanied by lower
                  scores than usual. Imbibitor struggles to make effective use
                  of any of the mode's buffs, lacks strong AoE damage, and
                  imposes severe limitations on team building due to his high
                  Skill Point costs. These factors often prevent him from being
                  paired with other damaging characters, which is one of the top
                  strategies in Pure Fiction. For these reasons, we are lowering
                  his rating.
                </li>
                <li>
                  <strong>[Welt]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Welt is a character who sees very little play, even among
                  other Niche characters in T3 and T4. His performance
                  consistently falls short of meeting the mode's full clear
                  requirements. Due to these factors, and the fact that
                  characters currently in T3 are generally outperforming him, we
                  are moving Welt down a tier to T4.
                </li>
                <li>
                  <strong>[Hook] & [Misha]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Hook and Misha have seen very little to no play or success
                  in Pure Fiction. As part of this update, we are moving both of
                  them into the "Forgotten Ones" tier (Tier 5) until such a time
                  that they regain meta relevance for the mode.
                </li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations — we place them on our internal
                'Watch list'. This helps us avoid hasty tier adjustments and
                ensures that any changes we make are well-considered and
                accurate. We welcome your feedback!
              </p>
              <ul>
                <li>
                  <strong>[Kafka & Black Swan]</strong> - Kafka, Black Swan, and
                  the DoT archetype have been struggling in recent phases of
                  Pure Fiction due to increasing difficulty and a lack of
                  dedicated Supports. Despite this, Kafka and Black Swan
                  maintain some of the highest play rates in the mode, even
                  though their scores have consistently fallen short of Apex
                  tier standards outside of DoT-favored buffs. We’re currently
                  debating whether these two should remain in the Apex tiers or
                  if a move to T1 would better reflect their current
                  performance.
                </li>
                <li>
                  <strong>[Himeko]</strong> - Himeko has underperformed relative
                  to our expectations for Apex T0 characters in this phase of
                  Pure Fiction. However, this phase may have been less than
                  ideal for her, so we’re closely monitoring her performance in
                  the next phase to see if she can bounce back. If she continues
                  to struggle, we may need to reassess her tier placement.
                </li>
                <li>
                  <strong>[Argenti]</strong> - Argenti has been on a roll in
                  Pure Fiction, thanks to enemy line-ups, buffs, and
                  vulnerabilities aligning perfectly with his strengths. His
                  recent performance was nothing short of dominant, resulting in
                  one of the highest average scores we’ve ever recorded. We’re
                  seriously considering raising him to T0, but we want to see if
                  he can maintain this dominance in a phase where the line-up
                  doesn’t favor physical vulnerability. If he continues to
                  excel, we’ll promptly move him up.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> - Ruan Mei is the most-played
                  character in Pure Fiction since the mode’s inception, largely
                  due to her versatility and ability to fit into most teams.
                  However, Robin has been gaining ground, establishing new
                  synergies and leading some of the strongest clears in the
                  mode. We’re currently debating whether Robin should be the
                  sole T0 amplifier for Pure Fiction or if Ruan Mei’s
                  flexibility is enough to keep her on par. We’ll continue to
                  observe both characters' performances, community perceptions,
                  and team dynamics before making any final decisions, but a
                  change may be on the horizon.
                </li>
              </ul>
              <h6>
                <strong>21/08/2024</strong>
              </h6>
              <ul>
                <li>
                  Added <strong>[Jiaoqiu]</strong> to the tier list. Our team
                  was impressed with Jiaoqiu’s consistency and clear potential
                  in the Pure Fiction - in both our initial testing and
                  theorycrafting. To start with we’re placing him in T1 but will
                  be watching the community's reception and his initial
                  performance closely with the expectation he may need to be
                  elevated up the list if he can demonstrate his potential.
                  We’ll specifically be on the lookout for how his non-Acheron
                  teams end up ranking among the scores as we feel Apex supports
                  must be capable of some flexibility.
                </li>
                <li>
                  With the release of Jiaoqiu our team re-evaluated all
                  characters within the Specialist category when deciding on his
                  category placement and found <strong>[Guinaifen]</strong> to
                  no longer be suited to the Specialist category. The main
                  reason is that Guinaifen’s damage output isn't the reason
                  she’s brought to teams - her debuffs are. Specialist
                  character’s all have some capacity to do meaningful damage on
                  their own, while Amplifier’s are brought almost exclusively
                  for their buffing and debuffing capabilities. As a result
                  we’re moving Guinaifen into the Amplifier category and
                  updating her ratings accordingly.
                </li>
                <li>
                  The promised big update to the MoC tier list (similar to the
                  recent PF and AS ones) is still in progress and we're aiming
                  to release it together with the upcoming 2.5 patch, so in 3
                  weeks.
                </li>
              </ul>
              <h6>
                <strong>12/08/2024</strong>
              </h6>
              <p>
                We have made several changes to the Apocalyptic Shadow based on
                the new phase testing & data and the community feedback:
              </p>
              <ul>
                <li>
                  <strong>[Topaz]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Topaz has been consistently one of the highest scoring
                  Specialist characters across both phases of AS - all while
                  having an incredibly high play rate. With Topaz’s data-based
                  results and in-game testing both being some of the best, it’s
                  clear she’s the go-to powerhouse for the mode who is able to
                  support multiple different carries in achieving very strong
                  clears. For these reasons we’re raising her to Apex status.
                </li>
                <li>
                  <strong>[Robin]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Robin has some of the most successful teams, sports some
                  of the highest (and consistent) scores and has one of the
                  highest usage rates of any character in Apocalyptic Shadow.
                  Based on her exceptional performance she is rated too low - in
                  this update we’re correcting that and placing her in the Apex
                  tier where she belongs.
                </li>
                <li>
                  <strong>[Bronya]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Bronya has had consistently good results across both
                  phases of AS, thanks to her best in slot position alongside
                  one of the strongest characters of the mode - Boothill. On top
                  of this she also sees use in other teams which score highly as
                  well. We feel compared to other amplifiers Bronya is currently
                  rated too low for how well she and her teams performs for this
                  reason we’re raising her up a tier.
                </li>
                <li>
                  <strong>[Hanya]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. Hanya is seeing more use overall in a wider variety of
                  teams and with higher scores than expected. For this reason we
                  don’t feel it’s fair to keep her so low on the tier list and
                  are moving her up from T3 to T2.
                </li>
                <li>
                  <strong>[Acheron]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Tier 0 is reserved for characters that can absolutely
                  trivialize the mode we’re ranking them in - regardless of the
                  enemies or buffs they’re working with. “Brute force” should
                  always be an option for these characters and we expect them to
                  perform at a high level while doing it. Acheron was one of the
                  best performing damage dealers of the first AS, sporting
                  fantastic scores across the board but this time around she has
                  performed below expectations. While both sides enemies had
                  Lightning RES and there was no synergistic buffs for Acheron
                  to take advantage of, we hold T0 to a high standard. Due to a
                  lackluster performance in overall data and underwhelming
                  scores in our own testing (without her Signature) we feel it
                  appropriate to move Acheron alongside Dr Ratio in T0.5 for
                  now.
                </li>
                <li>
                  <strong>[Imbibitor Lunae]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Imbibitor Lunae hasn’t produced results matching the
                  quality of the peers he shares Tier 1 with. Based on the
                  testing and data of the first two AS phases we feel he is
                  closer in performance to Jingliu in T1.5 and will be moving
                  him to accompany her there for the time being.
                </li>
                <li>
                  <strong>[Selee]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Seele had a very good first encounter in the first AS
                  against Cocolia with adds she could slay for continual resets
                  and a Quantum-weak boss, but it’s now clear without these
                  boons Seele struggles. We’re dropping Seele down from T1 to
                  T1.5 and will be watching her in the next phase closely in
                  case further adjustments may be needed.
                </li>
                <li>
                  <strong>[Jing Yuan]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Our General has not been having the best time in AS in
                  either of its phases so far, sporting some of the lowest data
                  based scores and play rates of all the limited characters.
                  While Jing Yuan is certainly serviceable in the mode based on
                  our testing, we feel he is currently rated too highly for his
                  performance and will be dropping him down half a tier.
                </li>
                <li>
                  <strong>[March 7] and [Fire Trailblazer]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Both March 7th (Ice) and Trailblazer (Fire) have seen
                  close to no play in either of the initial two phases of AS and
                  both have alternative forms that are mutually exclusive and
                  see immense gameplay and value in the mode. As most players
                  almost always opt for the alternative forms of these
                  characters due to them being far more suited to the AS mode
                  and the initial forms performing so poorly we’re moving both
                  to the “Forgotten Ones” tier.
                </li>
              </ul>
              <p>
                When characters perform outside ours or the community's
                expectations in a given Phase our team puts them on an internal
                'watch list'. Characters that continue to over or underperform
                while on this list have a high likelihood of moving up or down
                on in future updates. The reason for this list is to avoid
                incorrect and/or knee jerk character changes as much as
                possible. For full transparency here are the characters we’re
                currently watching and why. Please let us know what you think!
              </p>
              <ul>
                <li>
                  <strong>[Yunli]</strong> - Yunli performed exceptionally well
                  in her first phase of AS but had access to a buff tailor-made
                  for her on top of both encounters having some level of
                  Physical weakness. With these points considered, our team
                  wants to see how Yunli performs in the next phase before
                  moving her.
                </li>
                <li>
                  <strong>[Himeko]</strong> - Himeko continues to perform well
                  in both Super Break and Follow-Up based teams, making her
                  candidate for the move into the Apex section of the tier list.
                  We’re going to keep an eye on her for another phase to see if
                  she can maintain her strong showing.
                </li>
                <li>
                  <strong>[Argenti]</strong> - Argenti sees low play rate but
                  has the potential to achieve some reasonable scores when
                  played around in optimal team set-ups. Our team currently
                  feels he may be rated too low but want to see one more phase
                  from him before a move.
                </li>
                <li>
                  <strong>[Blade]</strong> - Blade has had another phase of
                  struggling in AS with most of his highest rated runs being
                  propped up by Jingliu. Unlike Blade though, Jingliu has many
                  other successful team compositions - many of which outperform
                  the Blade variants. We’re keeping an eye on Blade but do feel
                  he lacks a home and is behind other characters currently
                  sharing his tier based on results so far.
                </li>
                <li>
                  <strong>[Xueyi]</strong> - Against an AS with Quantum weak
                  enemies Xueyi absolutely dominated but without them, she has
                  seen very little use. We are currently discussing whether
                  Xueyi should maintain her incredibly high Specialist placement
                  despite having such limited use in the mode.
                </li>
                <li>
                  <strong>[Kafka & Black Swan]</strong> - Kafka and Black Swan’s
                  performance started strong but has taken quite a fall this
                  phase of AS, owing to the lackluster DoT related buffs and the
                  lack of Lightning Weak enemies for the DoT archetype to
                  exploit. We’re not planning on moving them at this stage but
                  are keeping an eye on their long term performance in the mode.
                </li>
                <li>
                  <strong>[Silver Wolf]</strong> - Silver Wolf was one of the
                  top performers of the first AS phase but has seen a sharp
                  decline in performance in the current phase. Part of the
                  reason for this is the poor enemy line-up for both Acheron and
                  Mono Quantum teams in the current phase. With 2 out of 3 of
                  Silver’s best team archetypes having bad match-ups we feel it
                  could just be a bad phase for her but will be watching her
                  performance in the future to see if she still deserves the
                  Apex rating.
                </li>
              </ul>
              <h6>
                <strong>31/07/2024</strong>
              </h6>
              <ul>
                <li>
                  Added <strong>[Yunli]</strong> and{' '}
                  <strong>[March 7th - The Hunt]</strong> to the tier list:
                </li>
                <ul>
                  <li>
                    <strong>[Yunli]</strong> is without a doubt a powerhouse
                    character with her signature equipped and in our testing she
                    completely demolished all modes with ease. BUT our tier list
                    does not include signature Light Cones and without her
                    Signature, Yunli deals less damage but more importantly
                    loses the massive multiplier for her to be hit it grants.
                    Without this Yunli will be hit far less often in combat,
                    resulting in lower energy gain and less damage unless
                    fighting enemies with many AoE and Blast attacks. Yunli can
                    make up for the loss of her signature and regain her higher
                    chance to be hit by pairing her with Lynx but this is at the
                    cost of using another incredibly potent sustain with her
                    like Huohuo. Our team still considers Yunli incredibly
                    strong in all game modes but have placed her conservatively
                    to begin with to observe how the community plays and
                    performs with her when not using her signature. We may raise
                    her if players are happy with or find other ways to mitigate
                    the loss of the signature.
                  </li>
                  <li>
                    <strong>[March 7th - The Hunt]</strong> is an exceptionally
                    strong character with a kit halfway between damage dealer
                    and support and halfway again between Breaker and CRIT carry
                    - making her an anomaly among the current cast of the game.
                    For these reasons we’ve placed her in the Specialist
                    category alongside other characters with similarly unique
                    kits. Additionally we’ve placed March at a power level we
                    feel represents her potential at the exact moment of her
                    release - it’s quite likely she’ll climb the tier list as
                    she receives her remaining 3 Eidolons and teammates her
                    unique kit synergises more effectively with.
                  </li>
                </ul>
                <li>Big changes to the MoC tier list:</li>
                <ul>
                  <li>
                    <strong>[Dr. Ratio]</strong> T1{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T0.5 in MoC. Dr. Ratio has consistently performed above
                    expectations in many of the MoC’s of 2.x, surprising us and
                    the community and often ranking above characters placed
                    firmly above him on the tier list. One of the biggest
                    contributors to this is the continual stream of Follow-Up
                    Attack Archetype supports that have been added to the game.
                    After reviewing the community feedback, our testing and
                    observing the data, we’ve decided to move Ratio into tier
                    0.5 and the Apex Category.
                  </li>
                  <li>
                    <strong>[Jingliu]</strong> &{' '}
                    <strong>[Imbibitor Lunae]</strong> T0.5{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    T1. The poster children of 1.x who reigned throughout these
                    patch cycles have begun to face stiff competition during the
                    2.x era with the release - and continual improvement - of
                    multiple powerhouse teams in the FUA, Break and Debuff
                    Archetypes. After monitoring community feedback and doing
                    our own testing across multiple MoC’s we’re finally moving
                    these two legendary characters out of the Apex tier. The
                    reason for this is the definition of the Apex tier - they
                    must be characters that can brute force and trivialize
                    content regardless of weakness and turbulence. Throughout
                    2.x Imbibitor and Jingliu have had their moments of
                    dominance but have increasingly not felt like the free ride
                    in content they used to be compared to characters like Dr
                    Ratio, Acheron or Firefly in their ideal teams. It’s for
                    these reasons they’re moving down for now, but we’ll
                    continue to watch them long term especially if they gain new
                    support and move them back into Apex if required.
                  </li>
                </ul>
                <li>Other changes to the MoC tier list:</li>
                <ul>
                  <li>
                    <strong>[Himeko]</strong> T3{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T2. She's performing well in Firefly’s team compositions for
                    those who don’t have Firefly herself.
                  </li>
                  <li>
                    <strong>[Asta]</strong> T3{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T2. Sees use in Firefly’s Super Break team as an alternative
                    to Ruan Mei for those who do not have her. Also sees some
                    use in DoT teams occasionally. These facts combined give her
                    enough meta relevance in our opinion.
                  </li>
                  <li>
                    <strong>[Lynx]</strong> T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T1.5. With the release of Yunli, Lynx’s meta relevance
                    increases. Lynx is a core team member for Yunli’s that do
                    not have access to their signature and for this reason we’re
                    raising her up half a tier as she has a unique meta niche
                    compared to Bailu and Gepard.
                  </li>
                  <li>
                    <strong>[Guinaifen]</strong> T3{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T2. Guinaifen sees consistent use in MoC as a part of both
                    the “Debuff” and “DoT” archetypes, appearing in Acheron and
                    Kafka/Black Swan teams. It’s clear to us she is the favored
                    4-star DoT character of choice over Sampo and for this
                    reason we’re moving her up to where Sampo was placed before.
                  </li>
                  <li>
                    <strong>[Silver Wolf]</strong> T1.5{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T1. Silver Wolf has seen consistent high usage and strong
                    performance through Penacony and sees use as an option in
                    two of the strongest teams in the game - Dr Ratio teams and
                    Acheron teams. For these reasons we’re raising her up half a
                    tier.
                  </li>
                  <li>
                    <strong>[Yanqing]</strong> T4{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    T5. He has failed to perform over multiple MoC’s post
                    Aventurine release (which is why he was raised in the first
                    place), so we're dropping him back to the Forgotten Tier.
                  </li>
                  <li>
                    <strong>[Sampo]</strong> T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    T3. Sampo’s usage in MoC is consistently low and his
                    performance hasn’t been outstanding. He also competes with
                    Guinaifen - a character with a similar role but who fits the
                    current meta better. Our team feels Sampo feels more
                    appropriately placed as a Niche character for these reasons.
                  </li>
                </ul>
                <li>
                  When updating our Tier list we have a number of characters
                  we’re monitoring over a number of MoC’s before deciding
                  whether to adjust their rating or not. We do this to ensure we
                  minimize knee jerk moves based on favorable/unfavorable
                  turbulence or “flavor of the month” hype. A downside of this
                  is sometimes we are slower to make updates than the community
                  would like - as a middle ground here are some of the
                  characters we are currently monitoring for MoC and why:
                </li>
                <ul>
                  <li>
                    <strong>[Kafka]</strong> & <strong>[Black Swan]</strong> -
                    The DoT archetype has gone multiple patches without any
                    additional support through new characters, Cones or Relic
                    sets while other Archetypes have had plenty. We’re keeping
                    an eye on whether the two core members of this archetype -
                    Kafka and Black Swan - may need to move to reflect the
                    relative power increase in the meta.
                  </li>
                  <li>
                    <strong>[Boothill]</strong> came out of the gates swinging
                    in MoC but has had lackluster showings since - especially
                    compared to his fellow breaker Firefly. We’re currently
                    keeping an eye on him to see how he fits into future MoC
                    line-ups and if a move may be needed.
                  </li>
                  <li>
                    <strong>[Jade]</strong> has done well in her first MoC
                    appearance, but the turbulence was incredibly favorable for
                    her. We recognize her strong first showing but are waiting
                    to see how she does with a less favorable turbulence before
                    moving her up.
                  </li>
                  <li>
                    <strong>[Argenti]</strong> is also on our 'watchlist' and
                    he's on the verge to moving to T2 just like Himeko did - we
                    just need to test him more.
                  </li>
                </ul>
              </ul>
              <h6>
                <strong>13/07/2024</strong>
              </h6>
              <ul>
                <li>
                  We have updated the Pure Fiction tier list to include our
                  post-Jade testing, the meta trends in the last 3 phases and
                  the community feedback:
                </li>
                <li>
                  <strong>[Argenti]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. While in the past, Argenti struggled in phases where
                  Physical-weak enemies were sparse, things have changed
                  recently - he consistently scores 30k points regardless of the
                  enemy setup and buffs, and even reaches 35k when everything
                  goes his away. This combined with the fact that he isn't
                  reliant on other limited characters to perform well (though
                  they do improve his performance even more) as he can be paired
                  with 4-star supports like Hanya and Tingyun is enough for us
                  to raise him from the Meta bracket to the Apex bracket. We
                  will continue observing him and his best teams in the next
                  phases to see if another upgrade will be required.
                </li>
                <li>
                  <strong>[Clara]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Clara's performance is really stable and she rarely drops
                  below 30k points regardless of the buff and enemy setup. She
                  also is very flexible, working well in multiple setups -
                  whether it's double DPS or hypercarry teams - and on top of
                  that, she can be played without any limited characters.
                  Because of that we've raising her to T1 and similar to
                  Argenti, we will continue observing her as she has potential
                  to jump to the Apex bracket.
                </li>
                <li>
                  <strong>[Topaz]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Despite being a completely single target Follow-Up
                  character, Topaz has proven herself to be an asset to multiple
                  of the top Pure Fiction Damage Dealers namely Himeko, Herta
                  and Clara. Topaz sees particularly strong performance during
                  Pure Fiction cycles with buffs related to Follow-Up’s thanks
                  to the sheer frequency of Numby’s attacks when partnered with
                  another Follow-up attacker. But she also earns respectable
                  scores during non-follow-up phases, thanks to her supportive
                  kit and reasonable single target damage. Topaz assists the top
                  carries of the mode in different ways - she helps Himeko kill
                  and break elite/fire weak enemies making churning through the
                  waves with her talent smoother. She assists Herta with single
                  target and getting initial 50% hp triggers to start her
                  follow-up chains and finally majorly boosts Clara’s single
                  target damage against the tougher enemies of the mode. Topaz
                  has proven herself time and time again and we feel her move up
                  the ranks is long overdue as such we’re moving her two tiers
                  at once to what we think is her rightful place.
                </li>
                <li>
                  <strong>[Asta]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Asta consistently scores above 30k points and she's
                  featured in a plethora of teams - often replacing Ruan Mei for
                  those who don't have the crazy scientist or use her on the
                  other side. Despite the fact that Asta is the second choice,
                  she carries her weight and performs really well which is
                  enough for us to raise her one tier up.
                </li>
                <li>
                  <strong>[Hanya]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. Hanya's performance in the last 2 phases exceeded our
                  expectations. While her usage rate remains at the lower end
                  (though it's slowly climbing), she is often paired with
                  Argenti and works really well with him - even reaching the
                  points of Argenti+Sparkle teams. That's enough for us to raise
                  her from the Niche bracket, but because she's nearly
                  exclusively paired with Argenti, we're only upgrading her by 1
                  tier.
                </li>
                <li>
                  <strong>[Acheron]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Acheron's performance dropped in recent phases when you
                  look at teams that aren't part of the 'Dotcheron' team
                  archetype - honestly, it's the only team where she shines
                  nowadays in Pure Fiction. When you look at her performance
                  without Black Swan and/or Kafka, she barely reaches 25k points
                  on average as neither the FUA or DoT buffs help with her
                  Ultimate-oriented playstyle. Still, the Dotcheron team is one
                  of the two best teams to use during the DoT phase while still
                  being competitive in the FUA phase - easily scoring 30k points
                  then but because of Acheron's reliance on the limited Nihility
                  duo, we're dropping her from the Apex bracket as we expect the
                  characters there to be able to brute-force the content
                  regardless of the current buffs and without relying on being
                  carried by others.
                </li>
                <li>
                  <strong>[Boothill]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Sadly, the space cowboy struggles a lot in Pure Fiction -
                  even more than we anticipated on his release - and we're
                  dropping him down a tier to the Forgotten bracket, because
                  honestly we also struggled to find a niche scenario where you
                  would use him in Pure Fiction.
                </li>
                <li>
                  <strong>[Physical MC]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Now that the Imaginary MC took the meta by storm, there's
                  really no reason to use the 'original' version of the MC and
                  we're dropping them to the Forgotten tier.
                </li>
                <li>
                  <strong>[Yukong]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. While Yukong can still be used with Cooler Daniel in other
                  modes, in Pure Fiction he struggles really hard and this
                  impacts Yukong's usage rates and performance. Because of that
                  we're dropping her to the Forgotten tier.
                </li>
                <li>
                  <strong>[Natasha]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Not only in Pure Fiction healers have a rougher time as
                  you don't really need them, she brings nothing else to the
                  table compared to other Sustain options so we're dropping her
                  to the Forgotten tier. Poor Natasha - you served us well.
                </li>
                <li>
                  We're also looking into <strong>[Gallagher]</strong> as he has
                  the potential to jump to the T0 tier, but we want to wait one
                  more phase to see how he compares to Aventurine
                  performance-wise before pulling the trigger.
                </li>
              </ul>
              <h6>
                <strong>10/07/2024</strong>
              </h6>
              <ul>
                <li>
                  <strong>[Jade]</strong> was added to the tier list,
                </li>
                <li>
                  <strong>[Himeko]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0 in Pure Fiction. Himeko has been incredibly close to T0 for
                  a while now and the release of Jade is more than enough to
                  push her firmly across the line as the two pair very well
                  together completely dominating the mode - it's not that Himeko
                  needs Jade to perform well as she works very well with Herta
                  or other DPS, but Jade gives her another option when it comes
                  to team building, allowing for even more flexibility.
                </li>
                <li>
                  <strong>[Blade]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5 in Pure Fiction. Although Blade isn't Jade's best
                  teammate, when played alongside her he receives an incredible
                  power boost - primarily thanks to Jade's damage assistance and
                  the increased frequency of his Follow-up attacks. As Blade has
                  a new best team and it's performance is impressive, it only
                  makes sense to raise him up a tier.
                </li>
                <li>
                  <strong>[Black Swan]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5 in Pure Fiction. Jade sets a baseline of power for the
                  Specialist category in Pure Fiction and as such our previous
                  reigning powerhouse, Black Swan, will be moving down to make
                  room for her (as she is much closer in power level to Kafka
                  than Jade).
                </li>
                <li>
                  <strong className="red">EDIT!</strong>{' '}
                  <strong>[Argenti]</strong> and <strong>[Acheron]</strong>{' '}
                  ratings weren't touched with Jade's release because we're
                  waiting for the latest Pure Fiction data before deciding what
                  to do with them. Argenti is definitely on the rise
                  performance-wise, even in phases that don't suit him well,
                  while the opposite can be said about Acheron (outside of
                  Dotcheron teams she kinda struggles and that team requires
                  multiple limited characters), but we want to check the data
                  before moving either of them.
                </li>
              </ul>
              <h6>
                <strong>28/06/2024</strong>
              </h6>
              <p>
                The "Super Break Team Core" is a squad of 3 units that
                absolutely obliterates Apocalyptic Shadow and it consists of
                Harmony Trailblazer, Ruan Mei and Gallagher. It’s no
                exaggeration that these 3 units can be played alongside almost
                any damage dealer with reasonable Toughness damage against the
                enemies vulnerabilities and achieve a 3-star score. We’ve seen
                this team dominate in Apocalyptic Shadow across the board but
                most commonly alongside Himeko, Argenti, Boothill, Firefly,
                Clara, Dr Ratio, Xueyi and Sushang. There are even more examples
                but the point is clear - this team completely smashes the mode.
              </p>
              <p>
                The existence of this team presents an interesting problem as it
                allows many characters to perform far above their usual station
                - should they be evaluated with this best team or on their next
                best option? Part of our initial assessment of characters
                included whether or not they could function with or without this
                team or even a portion of it. Team flexibility outside of the
                Super Break Team Core is important and the community has pointed
                out alternatives for some characters leading to a need to raise
                them up on the Apocalyptic Shadow tier list. Here are the
                changes we made:
              </p>
              <ul>
                <li>
                  <strong>[Harmony Trailblazer]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0 in AS. We’re raising Harmony Trailblazer to Tier 0. Whether
                  played in the same team or not, Ruan Mei and HMC completely
                  dominate Apocalyptic Shadow and while there is an argument to
                  be made that Ruan Mei is more generic, Harmony Trailblazer is
                  still a unit you’ll almost always be making use of on one side
                  or another and is a huge damage amplifier for many of the top
                  characters. Harmony Trailblazer is closer to power than Ruan
                  Mei than they are to Silver Wolf so we feel it’s correct to
                  place them together for now.
                </li>
                <li>
                  <strong>[Himeko]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1 in AS. Our initial placement for Himeko on the tier list
                  was a conservative one. Himeko is one of the biggest abusers
                  of the Super Break Team Core, takes excellent advantage of the
                  first set of Apocalyptic Shadow buffs and has a good match up
                  against the Fire-weak enemy Argenti. Our team had some
                  concerns about Himeko’s performance outside of Super Break
                  after the favorable enemy and buffs pass, but after evaluating
                  community feedback, suggestions and discussion we've done
                  further testing and agree Himeko deserves a higher spot on the
                  tier list. Himeko can still find success outside of Super
                  Break teams in follow-up attack compositions (albeit they are
                  certainly weaker) but also as a supportive AoE breaker in
                  other break compositions such as Firefly teams. This
                  flexibility gives us confidence Himeko will continue to be a
                  strong pick even without relying on the Super Break team and
                  even allows her to free up Ruan Mei in Firefly's Super Break
                  team under certain conditions, offering tremendous value on
                  that front. As always we appreciate the communities feedback!
                </li>
                <li>
                  <strong>[Xueyi]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1 in AS. The community feels Xueyi is rated to low on the
                  tier list as while yes she is incredible at abusing the Super
                  Break Team Core (Ruan, Harmony Trailblazer, Gallagher), she
                  also has incredible performance when played in Mono Quantum,
                  meaning she can thrive even without monopolizing the best
                  units for the mode. As a Break-scaling character, the current
                  set of buffs is incredible for Xueyi but despite this, having
                  access to multiple teams, great Toughness damage and the
                  ability to hit enemies that aren't Quantum-weak, gives us
                  enough confidence she'll continue to thrive even without it.
                  We’re raising her up a tier because of that.
                </li>
                <li>
                  <strong>[Qingque]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5 in AS. We've rated Qingque based on her average
                  performance in Apocalyptic Shadow but the community has found
                  Qingque's high variance demands a higher tier placement. When
                  on the cusp of attaining 3-stars, Qingque holds an advantage
                  over other characters as on a lucky run she can overperform,
                  getting you over the edge in this incredibly tricky mode.
                  Thanks to her power to edge out a win when it might not be
                  possible otherwise we’re raising her up a tier.
                </li>
                <li>
                  <strong>[Luka]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2 in AS. Luka of course can be played in the Super Break team
                  albeit not as a top pick leading to low play rates. Despite
                  his low play rate, some players are seeing success with Luka
                  in Physical Break teams including Sushang or Boothill. Based
                  on this success we’re raising him up a tier and will continue
                  to keep an eye on him moving forward to see if he needs
                  further placement gains.
                </li>
                <li>
                  <strong>[Asta]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5 in AS. Asta isn’t the Best In Slot support anymore but in
                  Apocalyptic Shadow she is a close contender when it comes to
                  2nd or 3rd spot - which in some teams or for players without
                  the strongest unit compositions can be invaluable. Asta’s
                  Speed buff is great when it’s available, her break is
                  meaningful and in many teams she can serve as a pseudo
                  replacement for Ruan Mei, freeing her for your other team or
                  just filling the slot if you don’t have her. This flexibility
                  and value is enough for us to recognize she’s placed too low
                  and raise her up a tier.
                </li>
              </ul>
              <p>
                After considering the community's response there is a number of
                characters we’re waiting for additional data on or a new buff to
                make changes to on the tier list. The characters we’re currently
                watching closely are Dr. Ratio, Seele, Jingliu, Himeko (even
                after the move to T1 as she has the potential to move higher),
                Sushang, Misha, Robin and Lynx. As always thanks to the
                community for the valuable feedback and suggestions!
              </p>
              <p>
                <strong>27/06/2024</strong>
              </p>
              <ul>
                <li>
                  We have added the <strong>Apocalyptic Shadow</strong> tier
                  list. Since we only had one phase available to us and it's
                  heavily skewed toward Break characters, it was hard for us to
                  create the ratings (we based them both on the data gathered by
                  us and manual testing done for every characters). Once the
                  next phase starts and the theme for it will be revealed, the
                  tier list will become more accurate.
                </li>
              </ul>
              <h6>
                <strong>19/06/2024</strong>
              </h6>
              <p>New additions:</p>
              <ul>
                <li>
                  <strong>[Firefly]</strong> has been added to T0 in MoC and T1
                  in PF. Firefly’s release sees her skyrocket to the top of the
                  Break damage meta when it comes to the carries.
                </li>
                <ul>
                  <li>
                    Firefly's best team deals some of the most damage of any
                    team in the game while also almost completely ignoring the
                    weakness inherent to her Break archetype thanks to her kit.
                    Firefly can implant her own Weaknesses, amplify her own
                    toughness damage, has multiple ways to scale her Break
                    damage, has strong multi-target capabilities thanks to her
                    Speed and cleave and has a full team of incredibly
                    synergistic characters to play alongside her. It's just too
                    much.
                  </li>
                  <li>
                    We rank Firefly as the{' '}
                    <strong>
                      best Break carry, and one of the best overall damage
                      dealers in the game
                    </strong>{' '}
                    thanks to her all encompassing kit eliminating many of the
                    weaknesses of her archetype. As a Breaker, we’re placing her
                    in the Specialist category alongside her closest peer
                    Boothill.
                  </li>
                  <li>
                    We’re ranking her at{' '}
                    <strong>
                      Tier 0 in MoC as she crushes the mode, but Tier 1 in Pure
                      Fiction
                    </strong>{' '}
                    as her cleave-only kit and pure Break damage causes her to
                    require the assistance of a more AoE inclined character such
                    as Himeko to excel.
                  </li>
                </ul>
              </ul>
              <p>Changes:</p>
              <ul>
                <li>
                  <strong>[Ruan Mei]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0 in MoC. Ruan Mei is one of the most flexible, most used and
                  strongest supports in the game. Our team was waiting for one
                  of the top 3 amplifiers of Ruan Mei, Sparkle and Robin to
                  break away from the pack and show they are worthy to move
                  above the rest. As of 2.3 and the release of Firefly we feel
                  Ruan Mei now absolutely deserves the top spot. Ruan can be
                  played in almost every team in the game to some success but
                  also possess many top tier teams where she has no comparable
                  replacement (Boothill / Firefly / Superbreak teams) thanks to
                  her incredible break capabilities. For these reasons Ruan Mei
                  is moving to Tier 0 in MoC.
                </li>
                <li>
                  <strong>[Harmony MC E6]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5 in MoC and T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1 in PF. With the release of Firefly, Imaginary Trailblazer
                  finds a close to irreplaceable position in one of the best
                  teams in the entire game and also gains access to their long
                  awaited E6 raising the power level of the character by a
                  significant amount. After testing Imaginary Trailblazer with
                  Firefly and also observing how their position in the Break
                  meta has developed we’re updating their role and position on
                  the tier list - as we’re adjusting Trailblazer’s role from
                  Specialist to Amplifier. The reason for this is that in
                  Firefly’s teams Trailblazer's main home they primarily
                  contribute through their buffs and not their own personal
                  damage - we’ve also found this to be the case in many other
                  break teams. While their damage can be significant it’s their
                  buffs that secure them a position on most teams. Due to
                  Trailblazer’s reasonable performance throughout 2.2 and new
                  top tier team composition with Firefly in 2.3 we’re also
                  raising them up to Tier 0.5 in MoC and Tier 1 in Pure Fiction.
                </li>
                <li>
                  <strong>[Gallagher E6]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5 in MoC and T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5 in PF. As a completely Break-focused sustain with
                  debuffing capabilities, Gallagher is by far one of the biggest
                  winners of the Penacony patches overall. Gallagher finds
                  himself as one of if not the best sustains for multiple of the
                  best teams in the game - the latest of which is of course the
                  tremendously powerful Firefly Break team. When discussing
                  Gallagher and his position on Tier Lists, it’s almost easier
                  to try to justify why he wouldn't be top tier. Gallagher is
                  currently the uncontested best sustain in the Firefly’s team
                  (one of the best teams in the game), a top option in Acheron’s
                  team and also competes for a top position in Boothill’s best
                  team as well (also a very strong team). Outside of this, he
                  can also be used in other teams - but holding just these
                  positions in our eyes justifies makes it hard not to move him
                  up the list, for this reason we’re moving him up to Tier 0.5
                  in both MoC and PF.
                </li>
                <li>
                  <strong>[Himeko]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T3 in MoC. Himeko's performance in the most recent MoC
                  exceeded all expectations, but that's because all stars
                  aligned for her. Still, we are raising her one tier up and we
                  will continue to monitor her in the next phase.
                </li>
                <li>
                  <strong>[Fu Xuan]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5 in MoC. Fu Xuan has stood at the top of the MoC tier list
                  for a long time thanks to her great offensive buffs, break
                  potential, fantastic damage mitigation and protection
                  abilities. Though, after much internal discussion and with
                  input from the community, we have decided to move Fu Xuan down
                  half a tier. The driving force behind community sentiment and
                  our decision is Aventurine and his universally dominant kit.
                  He has proven he is invaluable in some of the most powerful
                  teams in the game but also is at the very least the 2nd best
                  option in most others. Fu Xuan still has her own best team’s
                  and has her highly valued CRIT RATE buff but Aventurine is
                  more flexible thanks to his overpowered Shielding, skill point
                  positive kit and single target break capabilities.
                </li>
              </ul>
              <h6>
                <strong>29/05/2024</strong>
              </h6>
              <ul>
                <li>
                  <strong>[Boothill]</strong> has been added to the tier list,
                </li>
                <ul>
                  <li>
                    Boothill is the new king of single target and absolutely
                    dominates the niche when set-up correctly, routinely
                    blasting enemies for 100’s of thousands of damage like it’s
                    absolutely nothing thanks to his incredible mostly non-gear
                    reliant Break-focused damage profile.
                  </li>
                  <li>
                    We’ve ranked Boothill as a Specialist due to the majority of
                    his damage being contingent on enemies being broken by his
                    attacks or already broken - both of which require some form
                    of interaction with Toughness. Boothill’s reliance on
                    breaking enemies means he cares about enemy weaknesses and
                    also enemies that can lock their toughness from being
                    depleted, the first of which can slow down his clear times
                    by varying degrees but the second of which can render him
                    completely helpless against that enemy.
                  </li>
                  <li>
                    There is no doubt that Boothill is more comfortable, easy
                    and far more powerful against enemies that are Physical
                    weak. This fact is the main driving force behind our
                    categorization of him. We acknowledge Boothill is more than
                    capable of brute forcing non-physical weak fights thanks to
                    his Ultimate but in our tests those fights were harder
                    required more planning and in many cases slower pushing him
                    closer to his more Break-focused brethren like Luka.
                  </li>
                  <li>
                    Boothill’s potential is incredible especially in the hands
                    of a knowledgeable player with the gear and roster to
                    support him, but as per our criteria event limited Signature
                    Light Cones are no allowed so we’ve had to penalize
                    Boothill’s ranking slightly as his Light Cone is a
                    substantial damage increase for him (~30%).
                  </li>
                </ul>
                <li>
                  <strong>[Pela]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5 in MoC. Based on overwhelming community support and
                  recommendation we're raising Pela to T1.5 alongside Silver
                  Wolf. A strong consensus has been reached that while Pela
                  isn't a best in slot support for many or even any characters
                  in the ultra endgame, she can be played in almost any team
                  giving her supreme flexibility. On top of this while she might
                  be behind in some compositions such as Jingliu's losing out to
                  Ruan Mei it's not by enough to knock her variants of each team
                  out of viability with many of them only being slightly behind.
                  We agree with both these points and feel raising her is
                  appropriate.
                </li>
                <li>
                  <strong>[Gallagher]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1 in MoC. Gallagher continues to find his way into more and
                  more characters' best team compositions thanks to his
                  Break-focused kit. Boothill being one such Break character of
                  course absolutely loves Gallagher and has him present in many
                  of his top teams. While Gallagher’s sustain may be harder to
                  manage than other characters, his Break and offensive
                  capabilities are undeniable in the team’s where he thrives and
                  with another top tier team composition under his belt we’ll be
                  raising him up a tier.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> - With the Release of Boothill,
                  Ruan Mei receives another top tier team while her closest
                  competitors Sparkle and Robin maintain similar positions. We
                  absolutely have our eye on Ruan and we will revisit her
                  position after further testing.
                </li>
              </ul>
              <h6>
                <strong>09/05/2024</strong>
              </h6>
              <p>
                Added <strong>Harmony MC</strong> to the tier list.
              </p>
              <h6>
                <strong>08/05/2024</strong>
              </h6>
              <p>
                The tier list has been fully reworked, so we wiped the old
                changelog as it would only bring confusion.
              </p>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              (
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
              )
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
            </strong>{' '}
            tier list. It shows{' '}
            {(sortOption === 'moc' || sortOption === 'moc_special') &&
              'how the character performs in the Memory of Chaos. Blast and single target damage are important here while AoE has niche uses against some bosses.'}
            {(sortOption === 'pure' || sortOption === 'pure_special') &&
              'how the character performs in the Pure Fiction. AoE is king here, followed by Blast while single target damage is mostly useless.'}
            {(sortOption === 'apo' || sortOption === 'apo_special') &&
              'how the character performs in the Apocalyptic Shadow. Single target and Break potential are heavily favored here.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="eidolon-switcher">
          <div className="custom-switch">
            <Switch
              checked={eidolonSpecial}
              onChange={() => setEidolonSpecial(!eidolonSpecial)}
              onColor="#009EEC"
              offColor="#484950"
              className="switch"
            />
            Show 4★ characters at Eidolon 6 (E0 otherwise)
          </div>
        </div>
        {eidolonSpecial === true ? (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc_special')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure_special')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo_special')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        )}
        {(sortOption === 'pure' || sortOption === 'pure_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Pure Fiction</span> Analytics for historical data!
            </h4>
            <Link to="/star-rail/pure-fiction">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'apo' || sortOption === 'apo_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Apocalyptic Shadow</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/apocalyptic-shadow">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'moc' || sortOption === 'moc_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Memory of Chaos</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/memory-of-chaos">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        <div className="employees-filter-bar hsr with-color">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-hsr">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-header sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                </div>
                <h4 className="apex">Apex Characters</h4>
                <p>
                  Characters in this bracket are the cream of the crop. They can
                  do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear MoC and PF.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header apex">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="meta"
                  />
                </div>
                <h4 className="meta">Meta Characters</h4>
                <p>
                  Characters in this bracket make clearing MoC and PF a breeze,
                  but compared to the Apex characters they require more work
                  from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header meta">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="niche" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                </div>
                <h4 className="niche">Niche Characters</h4>
                <p>
                  Characters in this bracket are lacking in one or many fields
                  of their role in MoC or PF. These characters still possess the
                  ability to clear all content with the right build, endgame
                  blessing, team or enemy line-up but have much lower play rates
                  and success rates than Meta and Apex characters.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header niche">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-4">
            <div className="tier-rating t-4">
              <span>T4</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon
                    icon={faFaceSadTear}
                    width="30"
                    className="forgotten"
                  />
                </div>
                <h4 className="forgotten">The Forgotten Ones</h4>
                <p>
                  Characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header forgotten">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> The Forgotten
                Ones <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-5">
            <div className="tier-rating t-5 te">
              <span>T5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Honkai: Star Rail | Prydwen Institute"
    description="The most popular tier list for Honkai: Star Rail that rates all available characters by their performance in Memory of Chaos, Pure Fiction and Apocalyptic Shadow. Find out who is the best!"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        path
        tierCategory
        ratings {
          moc
          moc_special
          pure
          pure_special
          apo
          apo_special
        }
        tierListNew
        tierListTags
      }
    }
  }
`;
